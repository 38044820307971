import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const Support = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Support - KATSANA® Integrated Fleet Management & Industrial Operation
          Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/support/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.katsana.com/support/" />
        <meta
          property="og:title"
          content="Support - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            We are here to help
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Support Center
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-6xl px-8 py-16 md:py-32 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <div className="grid grid-cols-2 gap-12 md:grid-cols-6">
            <div className="flex flex-col items-center justify-center col-span-2 p-8 bg-[hsla(198,27%,94%,1)] rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
                fill="currentColor"
                className="h-16 text-bluetext"
              >
                <g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M447.229,67.855h-43.181v-43.18C404.049,11.103,392.944,0,379.379,0H64.771C51.2,0,40.097,11.103,40.097,24.675V419.47    c0,13.571,11.103,24.675,24.675,24.675h43.181v43.181c0,13.571,11.098,24.675,24.675,24.675h209.729    c13.565,0,32.762-7.612,42.638-16.908l68.929-64.882c9.888-9.296,17.969-28.012,17.969-41.583l0.012-296.096    C471.904,78.959,460.8,67.855,447.229,67.855z M107.951,92.531v333.108h-43.18c-3.343,0-6.168-2.825-6.168-6.168V24.675    c0-3.343,2.825-6.168,6.168-6.168H379.38c3.337,0,6.168,2.825,6.168,6.168v43.181H132.626    C119.049,67.856,107.951,78.959,107.951,92.531z M441.24,416.737l-68.929,64.877c-1.412,1.327-3.251,2.628-5.281,3.867v-56.758    c0-4.238,1.709-8.051,4.528-10.888c2.844-2.819,6.656-4.533,10.894-4.533h61.718C443.213,414.602,442.233,415.799,441.24,416.737z     M453.385,388.626c0,1.832-0.334,3.954-0.839,6.168h-70.095c-18.721,0.037-33.89,15.206-33.928,33.928v64.024    c-2.202,0.445-4.324,0.746-6.168,0.746H132.626v0.001c-3.35,0-6.168-2.825-6.168-6.168V92.53c0-3.343,2.819-6.168,6.168-6.168    h314.602c3.343,0,6.168,2.825,6.168,6.168L453.385,388.626z"></path>
                    </g>
                  </g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M379.379,154.216H200.488c-5.114,0-9.253,4.139-9.253,9.253c0,5.114,4.14,9.253,9.253,9.253h178.891    c5.108,0,9.253-4.139,9.253-9.253S384.487,154.216,379.379,154.216z"></path>
                    </g>
                  </g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M379.379,277.59H200.488c-5.114,0-9.253,4.139-9.253,9.253c0,5.114,4.14,9.253,9.253,9.253h178.891    c5.108,0,9.253-4.139,9.253-9.253C388.632,281.728,384.487,277.59,379.379,277.59z"></path>
                    </g>
                  </g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M299.187,339.277h-98.698c-5.114,0-9.253,4.139-9.253,9.253s4.139,9.253,9.253,9.253h98.698    c5.108,0,9.247-4.139,9.247-9.253S304.295,339.277,299.187,339.277z"></path>
                    </g>
                  </g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path d="M379.379,215.903H200.488c-5.114,0-9.253,4.139-9.253,9.253s4.14,9.253,9.253,9.253h178.891    c5.108,0,9.253-4.139,9.253-9.253S384.487,215.903,379.379,215.903z"></path>
                    </g>
                  </g>
                </g>
              </svg>

              <a
                href="https://www.katsana.com/documentation/"
                className="mt-6 text-lg font-medium text-center border-b border-blue-900 border-dotted text-bluetext hover:font-semibold"
              >
                Documentation
              </a>
              <p className="mt-2 text-sm text-center text-bluetext ">
                Want to learn more about your KATSANA devices? Learn about our
                products and get your tips and tricks here.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center col-span-2 p-8 bg-[hsla(198,27%,94%,1)] rounded-md">
              <svg
                viewBox="0 0 60 60"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="h-16 text-bluetext"
              >
                <g>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M35.9,27H32.259l1.5-6.472a2.062,2.062,0,0,0-3.692-1.657L22.39,29.7A2.092,2.092,0,0,0,24.1,33h3.645l-1.5,6.472A2.037,2.037,0,0,0,27.4,41.816a2.09,2.09,0,0,0,.859.187,2.044,2.044,0,0,0,1.672-.874L37.612,30.3A2.092,2.092,0,0,0,35.9,27ZM28.275,40l-.086-.078,1.788-7.7A1,1,0,0,0,29,31H24.1l-.078-.145L31.728,20l.085.078-1.787,7.7A1,1,0,0,0,31,29h4.9l.077.145Z"
                  ></path>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M46,21h5.026a5,5,0,1,0,0-6H45.858A4,4,0,0,0,42,12H18a4,4,0,0,0-3.858,3H8.974a5,5,0,1,0,0,6H14v6H8.974a5,5,0,1,0,0,6H14v6H8.974a5,5,0,1,0,0,6h5.168A4,4,0,0,0,18,48H42a4,4,0,0,0,3.858-3h5.168a5,5,0,1,0,0-6H46V33h5.026a5,5,0,1,0,0-6H46ZM5,21a3,3,0,1,1,3-3A3,3,0,0,1,5,21Zm4.9-2a5,5,0,0,0,0-2H14v2ZM5,33a3,3,0,1,1,3-3A3,3,0,0,1,5,33Zm4.9-2a5,5,0,0,0,0-2H14v2ZM5,45a3,3,0,1,1,3-3A3,3,0,0,1,5,45Zm4.9-4H14v2H9.9a5,5,0,0,0,0-2ZM44,44a2,2,0,0,1-2,2H18a2,2,0,0,1-2-2V16a2,2,0,0,1,2-2H42a2,2,0,0,1,2,2ZM55,15a3,3,0,1,1-3,3A3,3,0,0,1,55,15Zm-4.9,4H46V17h4.1a5,5,0,0,0,0,2ZM55,39a3,3,0,1,1-3,3A3,3,0,0,1,55,39Zm-4.9,2a5,5,0,0,0,0,2H46V41ZM55,27a3,3,0,1,1-3,3A3,3,0,0,1,55,27Zm-4.9,2a5,5,0,0,0,0,2H46V29Z"
                  ></path>
                </g>
              </svg>

              <a
                href="https://www.katsana.com/support/installation-guidelines/"
                className="mt-6 text-lg font-medium text-center border-b border-blue-900 border-dotted text-bluetext hover:font-semibold"
              >
                Installation Guidelines
              </a>
              <p className="mt-2 text-sm text-center text-bluetext ">
                Planning to install KATSANA GPS by yourself? Get the
                instalaltion steps and guidelines here.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center col-span-2 p-8 bg-[hsla(198,27%,94%,1)] rounded-md">
              <svg
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="h-16 text-bluetext"
              >
                <g>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="m512 346.5c0-74.628906-50.285156-139.832031-121.195312-159.480469-4.457032-103.878906-90.347657-187.019531-195.304688-187.019531-107.800781 0-195.5 87.699219-195.5 195.5 0 35.132812 9.351562 69.339844 27.109375 99.371094l-26.390625 95.40625 95.410156-26.386719c27.605469 16.324219 58.746094 25.519531 90.886719 26.90625 19.644531 70.914063 84.851563 121.203125 159.484375 121.203125 29.789062 0 58.757812-7.933594 84.210938-23.007812l80.566406 22.285156-22.285156-80.566406c15.074218-25.453126 23.007812-54.421876 23.007812-84.210938zm-411.136719-15.046875-57.117187 15.800781 15.800781-57.117187-3.601563-5.632813c-16.972656-26.554687-25.945312-57.332031-25.945312-89.003906 0-91.257812 74.242188-165.5 165.5-165.5s165.5 74.242188 165.5 165.5-74.242188 165.5-165.5 165.5c-31.671875 0-62.445312-8.972656-89.003906-25.945312zm367.390625 136.800781-42.382812-11.726562-5.660156 3.683594c-21.941407 14.253906-47.433594 21.789062-73.710938 21.789062-58.65625 0-110.199219-37.925781-128.460938-92.308594 89.820313-10.355468 161.296876-81.832031 171.65625-171.65625 54.378907 18.265625 92.304688 69.808594 92.304688 128.464844 0 26.277344-7.535156 51.769531-21.789062 73.710938l-3.683594 5.660156zm0 0"
                  ></path>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="m180.5 271h30v30h-30zm0 0"
                  ></path>
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="m225.5 150c0 8.519531-3.46875 16.382812-9.765625 22.144531l-35.234375 32.25v36.605469h30v-23.394531l25.488281-23.328125c12.398438-11.347656 19.511719-27.484375 19.511719-44.277344 0-33.085938-26.914062-60-60-60s-60 26.914062-60 60h30c0-16.542969 13.457031-30 30-30s30 13.457031 30 30zm0 0"
                  ></path>
                </g>
              </svg>

              <a
                href="https://www.katsana.com/support/frequently-asked-questions/"
                className="mt-6 text-lg font-medium text-center border-b border-blue-900 border-dotted text-bluetext hover:font-semibold"
              >
                FAQ
              </a>
              <p className="mt-2 text-sm text-center text-bluetext ">
                Want a quick answer to your questions? Find andswers and general
                information quickly about our products and services here.
              </p>
            </div>
          </div>

          <div className="pt-24 mx-auto text-center">
            <h4 className="text-2xl font-medium">Can't find your answer?</h4>
            <p className="mt-2 mb-12 text-sm">
              We're here to help. Get in touch and we'll get back to you.
            </p>
            <StaticImage
              alt="katsana"
              src="../../static/images/graphics/support.png"
              objectFit="contain"
            />

            <p
              id="kayakoButton"
              className="inline-flex px-6 py-3 mt-8 font-semibold text-white rounded-full cursor-pointer md:flex md:mt-8 md:justify-center md:w-72 bg-primary md:text-center md:mx-auto"
            >
              Ask Us A Question
            </p>
          </div>
        </div>
      </div>

      <script
        dangerouslySetInnerHTML={{
          __html: `
            if(typeof window !== 'undefined' && window.document) {
              function toggleMessenger(){"minimized"===kayako.visibility()?kayako.maximize():kayako.minimize()}
              (function (d, a) {
                function c() {
                    var b = d.createElement("script");
                    b.async = !0;
                    b.type = "text/javascript";
                    b.src = a._settings.messengerUrl;
                    b.crossOrigin = "anonymous";
                    var c = d.getElementsByTagName("script")[0];
                    c.parentNode.insertBefore(b, c);
                }
                window.kayako = a;
                a.readyQueue = [];
                a.newEmbedCode = !0;
                a.ready = function (b) {
                  a.readyQueue.push(b);
                };
                a._settings = {
                  apiUrl: "https://katsana.kayako.com/api/v1",
                  teamName: "Katsana",
                  homeTitles: [{ locale: "en-us", translation: "Hello! 👋" }],
                  homeSubtitles: [
                    {
                      locale: "en-us",
                      translation:
                        "Welcome to Katsana. Let's chat — start a new conversation below.",
                    },
                  ],
                  messengerUrl: "https://katsana.kayakocdn.com/messenger",
                  realtimeUrl: "wss://kre.kayako.net/socket",
                  widgets: {
                    presence: { enabled: !0 },
                    twitter: { enabled: !1, twitterHandle: "null" },
                    articles: { enabled: !1, sectionId: 1 },
                  },
                  styles: {
                    primaryColor: "#F1703F",
                    homeBackground: "-192deg, #40364D 37%, #9B4779 100%",
                    homePattern: "https://assets.kayako.com/messenger/pattern-1--dark.svg",
                    homeTextColor: "#FFFFFF",
                  },
                };
                window.addEventListener("load", c(), {once: true});
              })(document, window.kayako || {}),
                kayako.ready(function () {
                  (kayako.config = { hideLauncher: !0 }),
                    (document.getElementById("kayakoButton").onclick = function () {
                      toggleMessenger();
                    });
                });
            }
        `,
        }}
      />

      <Cta />
    </Layout>
  );
};

export default Support;
